import { useEffect } from 'react';
import { usePaypalMessageContext } from '@/integrations/paypal-message/src/context/PaypalMessage';

// eslint-disable-next-line @typescript-eslint/no-namespace
declare module globalThis {
    let paypal: any;
    let document: any;
}

export const usePayPalMessageScript = (): void => {
    const { isPayIn4ViaPayPalEnable, payIn4ViaPayPalClientId, setIsScriptLoaded } = usePaypalMessageContext();

    const onScriptLoad = () => {
        setIsScriptLoaded(true);
    };

    useEffect(() => {
        if (!isPayIn4ViaPayPalEnable || !payIn4ViaPayPalClientId) return;

        const PAYPAL_MESSAGE_PATH = `https://www.paypal.com/sdk/js?client-id=${payIn4ViaPayPalClientId}&components=messages,buttons`;

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = PAYPAL_MESSAGE_PATH;

        globalThis.document.head.appendChild(script);

        script.addEventListener('load', onScriptLoad);

        return () => {
            script.removeEventListener('load', onScriptLoad);
            if (globalThis.document.head.contains(script)) {
                globalThis.document.head.removeChild(script);
                setIsScriptLoaded(false);
            }
        };
    }, [isPayIn4ViaPayPalEnable, payIn4ViaPayPalClientId]);
};
